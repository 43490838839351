import { graphql } from 'gatsby';
import React from 'react';
import Astrid from '../src/components/Astrid/Astrid';
import SingleParagraph from '../src/components/Auto/SingleParagraph/SingleParagraph';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import { Freya } from '../src/components/Freya/Freya';
import Gerd from '../src/components/Gerd/Gerd';
import Layout from '../src/components/Layout/Layout';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Hod from '../src/components/Hod/Hod';
import Modi from '../src/components/Modi/Modi';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import H2 from '../src/components/Titles/TitleH2/TitleH2';
import H4 from '../src/components/Titles/TitleH4single/TitleH4single';
import Tyr from '../src/components/Tyr/Tyr';
import Ymir from '../src/components/Ymir/Ymir';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';
import './navidad.scss';

class Christmas extends React.Component {
  state = {
    showModal: false,
  };

  handleMap = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  render() {
    const { pageContext } = this.props;
    const heimdallData = {
      name: this.props.data.allNavidadTitleBlock.edges[0].node.title,
      image: {
        url: this.props.data.allNavidadBlockImageInfoGeneral.edges[0].node.localImage
          .childImageSharp.fluid,
      },
      imageMobile: {
        url: this.props.data.allNavidadBlockMobileImageInfoGeneral.edges[0].node.localImage
          .childImageSharp.fluid,
      },
      map: 'true',
    };

    const modiData = {
      Image:
        'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/images/images/000/009/030/original/pap.png',
      Text:
        tt(
          'Desde el 19 de noviembre hasta el 8 de enero, PortAventura World se engalana para recibir la Navidad. Durante estos días, podrás pasar una experiencia inolvidable llena de magia en todos y cada uno de los puntos del parque. Desde los mágicos espectáculos que estarán presentes en las diferentes áreas del parque hasta los desfiles y sorpresas que hemos preparado especialmente para ti. En esta sección, encontrarás información relativa a los espectáculos que tendrán lugar durante el periodo navideño, así como promociones y descuentos especiales. Además, podrás comprobar información sobre fotografías del parque durante las Navidades y una sección adicional de galería que contiene imágenes de esta época mágica del año.',
          this.props.pageContext.locale
        ) || tt(this.halloweenText, this.props.pageContext.locale),
      link: this.props.data.allNavidadTitleBlock.edges[0].node.description,
    };

    const ymirData = {
      cards: [
        {
          title: tt('HORARIOS Y CALENDARIO', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/horarios-calendario', this.props.pageContext.locale),
          icon: 'calendar',
        },
        {
          title: tt('CÓMO LLEGAR AL RESORT', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/destino/planificar/como-llegar-a-portaventura', this.props.pageContext.locale),
          icon: 'LOCATION',
        },
        {
          title: tt('DESCUENTOS EN ENTRADAS ONLINE', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/promociones', this.props.pageContext.locale),
          icon: 'Methodofpayment',
        },
      ],
    };

    const h2Data = {
      title: this.props.data.allNavidadTitleBlock.edges[0].node.subTitle,
    };

    const tyrData = {
      title: this.props.data.allNavidadCardsTitle.edges[0].node.name,
      cards: this.props.data.allNavidadCardsSubBlock.edges.map((block) => ({
        MainImage: block.node.localImage.childImageSharp.fluid,
        Image: block.node.icon,
        Title: block.node.title,
        TitleURL: block.node.button1.cta,
        Text: block.node.description,
        button1: {
          cta: block.node.button1.cta,
          size: block.node.button1.size,
          color: block.node.button1.color,
          ctaText: block.node.button1.ctaText,
        },
        button2: {
          cta: block.node.button2.cta,
          size: block.node.button2.size,
          color: block.node.button2.color,
          ctaText: block.node.button2.ctaText,
        },
      })),
    };

    const freyaData = {
      title: tt('PROMOCIONES RELACIONADAS', this.props.pageContext.locale),
      cards: this.props.data.allNavidadPromosSubBlock.edges.map((item) => ({
        image: item.node.localImage.childImageSharp.fluid,
        title: item.node.title,
        imgsize: 'promoted',
        buttons: {
          cta: item.node.buttons.cta,
          size: item.node.buttons.size,
          color: item.node.buttons.color,
          ctaText: item.node.buttons.ctaText,
        },
      })),
    };

    const SingleParagraphInfo = {
      title: this.props.data.allNavidadSingleParagraphBlock.edges[0].node.title,
      titleTag: this.props.data.allNavidadSingleParagraphBlock.edges[0].node.titleTag,
      titleStyle: this.props.data.allNavidadSingleParagraphBlock.edges[0].node.titleStyle,
      titleAlign: this.props.data.allNavidadSingleParagraphBlock.edges[0].node.titleAlign,
      body: this.props.data.allNavidadSingleParagraphBlock.edges[0].node.body,
    };

    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
        options={this.props.pageContext.options}
      >
        <SEO
          title={this.props.data.allNavidadSeoData.edges[0].node._0.title}
          description={this.props.data.allNavidadSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} handleMap={this.handleMap} />
          <div className="navidad-content">
            <Modi data={modiData} />
            <NeoAesir
              locale={this.props.pageContext.locale}
              pageName={this.props.pageContext.pageName}
              options={this.props.pageContext.options}
              title={this.props.data.allNavidadBreadCrumb.edges[0].node.name}
            />
            <Hod
              data={this.props.data.allNavidadRichData.edges[0].node.rich_markup}
              url={this.props.pageContext.url}
              img={
                this.props.data.allNavidadBlockImageInfoGeneral.edges[0].node.localImage
                  .childImageSharp.fluid
              }
            />
            <BlueArrow />
            <Astrid
              pageContext={this.props.pageContext}
              handleMap={this.handleMap}
              modal={this.state.showModal}
            />
            <H2 data={h2Data} />
            <Tyr data={tyrData} />
            <SingleParagraph
              title={SingleParagraphInfo.title}
              titleTag={SingleParagraphInfo.titleTag}
              titleStyle={SingleParagraphInfo.titleStyle}
              titleAlign={SingleParagraphInfo.titleAlign}
              body={SingleParagraphInfo.body}
            />
            <Ymir data={ymirData} />
            <Gerd pageContext={this.props.pageContext} modal={this.state.showModal} />
            <div className="promotions-content">
              <H4 title={tt('PROMOCIONES RELACIONADAS', this.props.pageContext.locale)} />
              <Freya data={freyaData} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Christmas;

export const pageQuery = graphql`
  query christmas_query($locale: String!) {
    allNavidadSeoData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allNavidadBlockImageInfoGeneral(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    allNavidadBlockMobileImageInfoGeneral(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    allNavidadTitleBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          subTitle
          description
        }
      }
    }
    allNavidadCardsTitle(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
          title
        }
      }
    }
    allNavidadCardsSubBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          icon
          button1 {
            cta
            size
            color
            ctaText
          }
          button2 {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allNavidadPromosSubBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          description
          imgsize
          stars
          buttons {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allNavidadBreadCrumb(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          tag
          destination_url
          name
        }
      }
    }
    allNavidadRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            twitter_description
            twitter_title
            created_at
            updated_at
            rich_markup_type
          }
        }
      }
    }
    allNavidadSingleParagraphBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          titleTag
          titleStyle
          titleAlign
          title
          body
        }
      }
    }
  }
`;
